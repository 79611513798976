import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"

import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage";

const AppRoutes = () => {

  const [nome, setNome] = useState();
  const inputNome = (event) => {
    setNome(event.target.value)
    localStorage.setItem('nome', event.target.value)
  }

  useEffect(() => {
    if (localStorage.getItem('nome')) {
      setNome(localStorage.getItem('nome'))
    }
  }, [])

  return (<BrowserRouter>
    <Routes>
      <Route path="/login" element={<LoginPage inputNome={inputNome} nome={nome} />} />
      <Route path="/" element={<MainPage  nome={nome} />} />
    </Routes>
  </BrowserRouter>)

  }

export default AppRoutes;
