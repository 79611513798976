import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FaSignInAlt } from 'react-icons/fa'

const LoginPage = ({ inputNome, nome }) => {
  const navigate = useNavigate();

  const signButton = () => {
    if (nome) {
      navigate("/");
    }
  }

  useEffect(() => {
    if (localStorage.getItem('nome')) {
      navigate('/')
    }
  }, [])

  return (
      <div className="flex"><h1 className="title"><i>MOTIV <strong>BIKERS</strong></i></h1>
          <Form className="w-100">
          <InputGroup className="mb-3" size="lg">
            <Form.Control
              placeholder="Insira seu nome ou email"
              aria-label="Insira seu nome ou email"
              aria-describedby="basic-addon2"
              onKeyUp={(event) => inputNome(event)}
            />
            <InputGroup.Text id="basic-addon2" className="btn-warning" onClick={signButton}><FaSignInAlt /></InputGroup.Text>
        </InputGroup>
          </Form>

      </div>
    )
}
export default LoginPage;
