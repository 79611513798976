import React from 'react'

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FaShareSquare /* , FaSignInAlt */ } from 'react-icons/fa'

const EventoPage = ({ evento }) => {
  const share = () => {
    const text = `Motiv BIKERS \nTrilha: *${evento.trilha}*

Local de encontro: *${evento.local}*
Quando: *${evento.dia}/${evento.mes}/${evento.ano} às ${evento.partida}*
Previsão de chegada: *${evento.chegada !== undefined ? evento.chegada : 'não informado' }*
Distância: *${evento.distancia !== undefined ? evento.distancia : 'não informado' } km*
Altimetria: *${evento.altimetria !== undefined ? evento.altimetria : 'não informado' } metros*

Avisos importantes: *${evento.avisos}*;

Acesse https://www.motivbikers.com.br/, aqui sua trilha é pura diversão!`;

    const compartilha = `https://api.whatsapp.com/send?text=${encodeURI(text)}`;
    window.open(compartilha,'_blank');
  }
  return(
      <Card className="mt-3 no-radius">
        <Card.Header className="p-0">
          <Row className="m-0 p-0">
            <Col className="d-grid gap-2 p-0">
              <Button className="no-radius" onClick={share}><FaShareSquare /> Compartilhar</Button>
            </Col>
            {/*   <Col className="d-grid gap-2 p-0">
              <Button className="no-radius" ><FaSignInAlt /> Participar</Button>
            </Col> */}
          </Row>
        </Card.Header>
        <Card.Body className="pt-0 px-0">
          <Card.Img src="evento.jpg" alt="cabeçalho" className="no-radius"/>
          <Row className="px-3 mt-3">
            <Col><h3>Trilha</h3></Col>
          </Row>
          <Row className="px-3">
            <Col> { evento.trilha !== undefined ? evento.trilha : '-' }</Col>
          </Row>
          <Row className="px-4 mt-4">
            <Col lg="5" xs="5" className="btn btn-info1 no-radius p-0 px-2">
              <h2 className="text-right"><strong>{ evento.dia !== undefined ? evento.dia : '-' }</strong>{ evento.mes !== undefined ? evento.mes : '-' }</h2>
              <h3 className="text-right">{ evento.ano !== undefined ? evento.ano : '-' }</h3>
            </Col>
            <Col >
              <Row>
                <Col className="btn btn-info2 no-radius"><h5 className="text-right py-1">SAÍDA: { evento.partida !== undefined ? evento.partida : '-' }</h5></Col>
              </Row>
              <Row>
                <Col className="btn btn-info3 no-radius"><h5 className="text-right py-1">CHEGADA: { evento.chegada !== undefined ? evento.chegada : '-' }</h5></Col>
              </Row>
            </Col>
          </Row>
          <Row className="px-4">
            <Col lg="6" xs="6" className="btn btn-info4 no-radius py-3">
              <h4><strong>{ evento.distancia !== undefined ? evento.distancia : '-' } km</strong></h4>
              <h5 className="lh">DISTÂNCIA</h5>
            </Col>
            <Col lg="6" xs="6" className="btn btn-info5 no-radius py-3">
              <h4><strong>{ evento.altimetria !== undefined ? evento.altimetria : '-' } m</strong></h4>
              <h5 className="lh">ALTIMETRIA</h5>
            </Col>
          </Row>
          <Row className="px-3 mt-3">
            <Col><strong>Local de encontro:</strong> { evento !== undefined ? evento.local : '' }</Col>
          </Row>
          <Row className="px-3 mt-2">
            <Col><strong>Avios:</strong> { evento !== undefined ? evento.avisos : '' }</Col>
          </Row>
          { /* <Row className="px-3 mt-2">
            <Col><strong>Confirmardos:</strong></Col>
            <Col lg="12" sm="12" md="12" xs="12">1. Ciclista</Col>
          </Row> */ }
        </Card.Body>
      </Card>
)}

export default EventoPage
