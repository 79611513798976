import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const postEvent = async (form) => api.post('/evento', form);

export const getEvent = async (token) => api.get('/evento', {
  headers: { Authorization: `Bearer ${token}` }
});

export const shareEvent = async (evento) => api.post('/evento/share', evento);

export default api;
