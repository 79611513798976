import React from 'react'

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FaFile } from 'react-icons/fa'

const HeaderPage = ({ removeLocalStorage }) => {
  const novo = () => {
    removeLocalStorage();
  }

  return(
  <Card className="mt-2 no-radius">
      <Card.Header className="header-evento d-flex justify-content-between align-items-start p-0">
          <span className="my-auto">&nbsp; Eventos</span>
          <Button variant="warning" onClick={novo} className="btn btn-warning justify-content-end no-radius">Novo <FaFile /></Button>
      </Card.Header>
    </Card>
)}

export default HeaderPage
