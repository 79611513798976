import React, { useState } from 'react'

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// eslint-disable-next-line import/no-extraneous-dependencies
import { FaCheck } from 'react-icons/fa'

import { postEvent } from '../../../services/api';

const NewPage = ({ tokenStatus, nome }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({quem: nome});

  const handleForm = (name, value) => {
    setForm({...form, [name]: value});
}

const enviaForm = async () => {
  setLoading(true)
  const [ response ] = await Promise.all([
    postEvent(form)
  ])
  localStorage.setItem('token', response.data)
  setLoading(false)
  tokenStatus(true);
}

  const handleSubmit = (event) => {
    const formulario = event.currentTarget;
    event.preventDefault();
    if (formulario.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      enviaForm();
    }
  };

  return(
  <div>
    { !loading ?
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Card className="no-radius mt-3 body-color">
        <Card.Header className="header-color d-flex justify-content-between align-items-start p-0">
          <span className="my-auto">&nbsp; Formulário</span>
          <Button className="btn btn-warning justify-content-end no-radius" type="submit" >Salvar <FaCheck /></Button>
        </Card.Header>
        <Card.Body>
          <FloatingLabel
              controlId="floatingTrilha"
              label="*Trilha"
              className="mb-2"
            >
            <Form.Control
                type="text"
                name="trilha"
                placeholder="Nome da trilha"
                onChange={(e) => handleForm('trilha', e.target.value)}
                required />
            </FloatingLabel>
            <FloatingLabel controlId="floatingLocal" label="*Local de encontro" className="mb-2">
              <Form.Control type="text" name="local" onChange={(e) => handleForm('local', e.target.value)} placeholder="Local de encontro" required />
            </FloatingLabel>
            <FloatingLabel controlId="floatingData" label="*Data da trilha" className="mb-2">
              <Form.Control type="date" name="data" onChange={(e) => handleForm('data', e.target.value)} placeholder="Data da trilha" required />
            </FloatingLabel>
            <FloatingLabel controlId="floatingHoraPartida" label="*Horário de partida" className="mb-2">
              <Form.Control type="time" name="partida" onChange={(e) => handleForm('partida', e.target.value)} placeholder="Horário de partida" required />
            </FloatingLabel>
            <FloatingLabel controlId="floatingHoraChegada" label="Previsão de chegada" className="mb-2">
              <Form.Control type="time" name="chegada" onChange={(e) => handleForm('chegada', e.target.value)} placeholder="Previsão de chegada" />
            </FloatingLabel>
            <FloatingLabel controlId="floatingDistancia" label="Distância (km)" className="mb-2">
              <Form.Control type="number" name="distancia" onChange={(e) => handleForm('distancia', e.target.value)} placeholder="Distância" />
            </FloatingLabel>
            <FloatingLabel controlId="floatingAltimetria" label="Altimetria (metros)" className="mb-2">
              <Form.Control type="number" name="altimetria" onChange={(e) => handleForm('altimetria', e.target.value)} placeholder="Altimetria" />
            </FloatingLabel>
            <FloatingLabel controlId="floatingAvisos" label="*Avisos" className="mb-2">
              <Form.Control as="textarea" name="avisos" onChange={(e) => handleForm('avisos', e.target.value)} rows="3" placeholder="Avisos" required />
            </FloatingLabel>
            <div className="d-grid gap-2">
              <Button className="btn no-radius" variant="danger" disabled>* Preenchimento obrigatorio</Button>
            </div>
          </Card.Body>
        </Card>
      </Form>
      :
      <Card className="no-radius mt-3 body-color">
        <Card.Body>
          <h1 className='text-white'>Aguarde ...</h1>
          <img src="loading.gif" alt="loading" className="w-100" />
        </Card.Body>
      </Card>
      }
  </div>
)
}

export default NewPage
