import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

import EventoPage from "./EventoPage";
import HeaderPage from "./HeaderPage";
import NewPage from "./NewPage";

import { getEvent } from "../../services/api";

const MainPage = ({ nome }) => {
  const navigate = useNavigate();

  const [isToken, setToken] = useState(false);
  const [evento, setEvento] = useState({});

  const removeLocalStorage = () => {
    localStorage.removeItem("token");
    setToken(false);
  }

  const carregaEvento = async () => {
      const [ response ] = await Promise.all([
        getEvent(localStorage.getItem('token'))
      ])
      setEvento(response.data)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setToken(true)
      carregaEvento();
    }
    if (!localStorage.getItem('nome')) {
      navigate('/login');
    }
  }, [])

  const tokenStatus = (status) => {
    setToken(status)
    carregaEvento();
  }

  return(
    <div className="mb-5">
      <h1 className="event-title"><i>Bem vindo, <strong>{nome}</strong></i></h1>
      <HeaderPage removeLocalStorage={removeLocalStorage} />
      { isToken ?
      <EventoPage evento={evento}  />
      :
      <NewPage tokenStatus={tokenStatus} nome={nome} />
      }
    </div>
  )
}
export default MainPage;
